
// Cookies
// -------------------------------

.cookies-alert {
  position: fixed;
  display: none;
  left:0;
  bottom:0;
  width: 100%;
  background: $black;
  color: $white;
  @include z-index(999);

  > a, button {
    @include font-sans($font-size-large, 1);
    @include button-link($black, $white, $white);
    position: absolute;
    border: 0;
    right: 20px;
    top:50%;
    @include transform-3D(0, -50%, 0);
  }
}

.cookies-alert-holder{
  position: relative;
  width: 100%;
  overflow: hidden;

  p {
    @include font-sans($font-size-base, 32/20);

    a {
      @include underline-link($white, $white, $white, $white, 2px);
    }
  }
}

.cookies-alert-content{
  top:0px;
  left:0px;
  padding: 20px 250px 20px 20px;
}

@media (max-width: $smartphone) {
  .cookies-alert {
    > a, button {
      top:auto;
      bottom:0px;
      @include font-sans($font-size-base, 1);
    }
  }

  .cookies-alert-holder{
    position: relative;
    width: 100%;
    overflow: hidden;

    p {
      @include font-sans($font-size-small, 32/20);
    }
  }

  .cookies-alert-content{
    padding: 20px 20px 80px 20px;
  }
}