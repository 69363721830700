html{
  width: 100%;
  height: 100%;
}

body{
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  background: $white;
  font-family: $font-sans;
  color: $black;

  &.__isMobile {
    overflow: hidden;
  }
}
@media (max-width: $largeScreen) {
  body {
    //font-size: (18px*16)/20;
  }
}
@media (max-width: $smartphone) {
  body {
    //font-size: (16px*16)/20;
  }
}

main {
  margin: 0;
}
#wrap {}
.main-holder {
  position: relative;
  @include z-index($z-index-wrap);
  top:0px;
  left:0px;
  width: 100%;
  height: auto;
  overflow-x:hidden;
  overflow-y: hidden;
  font-size: 1em;
}

.wrap, .wrap-out {
  position: relative;
}




#helper {
  position: absolute;
  width: 100%;
  z-index: 0;
  top:0px;
  left:0;
  pointer-events: none;
}


.__mobile {

  overflow: hidden;

  #wrap {
    position: fixed;
    @include z-index($z-index-wrap-back);

    top: 0px;
    left: 0px;
    display: block;

    width: 100%;
    height: 100%;

    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    padding: 0 0 0;

    &.__back {
      @include z-index($z-index-wrap-back);
    }

    &.__front {
      @include z-index($z-index-wrap);
    }
  }

  .main-holder {
    position: relative;
    @include z-index(2);
  }

}

.trigger {
  position: relative;
  width: 100%;
  height: 1px;
  background: #000;
}