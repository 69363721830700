$a: 200px;

.main-filtermenu {
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  @include z-index($z-index-filtermenu);
  pointer-events: none;
}

.main-filtermenu__bg {
  position: absolute;
  width: 100%;
  height: 100%;
  left:0;
  top:0;
  background: rgba($white, 0.9);
}

.main-filtermenu__navigation {
  position: absolute;
  width: calc((100vw - 48px) / 4);
  min-width: 265px;
  height: 100%;
  left:0;
  top:0;
  display: flex;
  flex-direction: column;
  padding: 188px $padding-sizes 0;
  background: $assertive;
  pointer-events: all;

  a,button {
    background: transparent;
    text-align: left;
    border: 0;
    padding: 0;
    @include font-sans($font-size-base, 32/20);
    @include color-link($dark, $black);
    margin-bottom: 0.8em;
  }
}

.main-filtermenu__toggle {
  position: absolute;
  width: 50px;
  height: 50px;
  display: block;
  left: $padding-sizes;
  bottom: $padding-sizes;
  @include no-effect-link();
  pointer-events: all;

  svg {
    width: 50px;
    height: 50px;
    stroke: $black;
    stroke-width: 2px;
    path {
      fill: none;
      vector-effect: non-scaling-stroke;
    }
    transition: transform 500ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  }

  &.__close {
    svg {
      transform-origin: 50%;
      transform: rotate(45deg);
    }
  }
}

@media (min-width: 1800px) {
  .main-filtermenu__navigation {
    width: calc((100vw - 1040px) / 2);
    padding-left: 100px;
    padding-right: 100px;
  }
  .main-filtermenu__toggle {
    left: 100px;
  }
}

.main-filtermenu__toggle_mobile {
  display: none;
  position: absolute;
  left:0;
  bottom:0;
  width: 100vw;
  height: 80px;
  @include font-sans($font-size-base, 1);
  padding: 1.5em 1em;
  background: $assertive;
  color: $dark;
  outline: 0;
  text-align: center;
  text-decoration: none;
  pointer-events: all;
  &:hover {
    color: $black;
  }
}


@media (max-width: $desktop) {
  .main-filtermenu__toggle {
    display: none;
  }
  .main-filtermenu__toggle_mobile {
    display: block;
  }

  .main-filtermenu__navigation {
    width: 100%;
    padding: 0 calc(12.5% + 36px) 0;
    justify-content: center;
    button, a {
      font-size: $font-size-title-smartphone;
    }
  }
}




@media (max-width: $tabletPortrait) {
  .main-filtermenu__navigation {
    height: 100%;
    padding: 0 calc(12.5% + 19px) 0;
    justify-content: center;
    button {
      font-size: $font-size-title-smartphone;
    }
  }
}
@media (max-width: $smartphone) {

  .main-filtermenu__navigation {
    padding: 0 calc(12.5% + 19px) 0;
    button {
      font-size: $font-size-base;
    }
  }
}


