.default-section {
  margin: 0;
  padding-top: 208px;
}
@mixin default-section() {
  margin: 0;
  padding-top: 116px;
}

@media (max-width: $smartphone) {
  .default-section {
    padding-top: 192px;
  }
}

.button-link {
  @include font-mono($font-size-large, 1);
  @include button-link();
  text-transform: uppercase;
}
