
.slider-images {
  padding: 14.28vh 0 0 0;
  margin-bottom: 5.75em;

  .slider-scroll__holder-items {
    @include z-index(2);
    padding: 0;
    height: calc(100vh - 14.28vh);
    padding-bottom: 14.28vh;
  }

  .featured-item {
    pointer-events: all;
  }
}

@media (max-width: 1200px) {

}


@media (max-width: $tabletPortrait) {
  .slider-images {

  }
}



.__mobile {
  .slider-images {
    padding-top: 0;
    height: 80vh;
    .slider-scroll__holder-items {
      height: 80vh;
      padding: 0;
    }

    .featured-item {
      height: 80vh;
    }
  }
}



@media (max-width: $smartphone) {
  .slider-images {
    padding-top: 48px;
    height: auto !important;
    margin-bottom: 0;


    .slider-scroll__holder-items {
      position: relative;
      top:0;
      bottom: 0;
      left:0;
      height: auto !important;
      display: flex;
      flex-direction: column;
      padding: 0;
      will-change: transform;
    }

    .slider-scroll__item {
      padding: 0;
      margin-bottom: 24px;

      .image-holder {
        //@include aspect-ratio-obj(1284, 912);
        img {
          position: relative;
          width: 100%;
          height: auto;
        }
      }

      &.fake {
        display: none;
      }
    }

    .featured-item {
      height: auto  !important;
      margin-top: 48px;
      pointer-events: all;
    }
  }

  .featured-item__projects-preview {
    display: block;
    margin-top: 0 !important;
    text-align: center;
  }
}