@mixin lines-deco($bg) {
  &:before {
    display: block;
    content: '';
    top:0;
    left:0;
    border-bottom: 2px solid $bg;
    width: 100px;
    margin: 0 0 1.3em;
  }

  &:after {
    display: block;
    content: '';
    bottom:0;
    left:0;
    border-bottom: 2px solid $bg;
    width: 100px;
    margin: 1.3em 0 1em auto;
  }
}

@mixin lines-deco-small($bg) {
  &:before {
    display: block;
    content: '';
    top:0;
    left:0;
    border-bottom: 2px solid $bg;
    width: 45px;
    margin: 0 0 0.8em -0.5em;
  }

  &:after {
    display: block;
    content: '';
    bottom:0;
    left:0;
    border-bottom: 2px solid $bg;
    width: 45px;
    margin: 0.8em -0.5em 1em auto;
  }
}


