.scrollmagic-pin-spacer{
  pointer-events: none;
}
.slider-projects-preview {
  padding-top: 14.28vh;
  margin-bottom: 5.75em;

  .featured-item {
    @include z-index(1);
    height: calc(100vh - 28.56vh);
    width: calc(100vw - 596px);
    pointer-events: all;
  }

  .slider-scroll__holder-items {
    @include z-index(2);
    padding: 0 0 0 40vw;
    height: calc(100vh - 14.28vh);
    pointer-events: none;
  }

  .slider-scroll__item {
    @include no-effect-link();
    padding: 0 0 0 $padding-sizes;
    background: $white;
    pointer-events: all;
    color: $black;

    &:first-of-type {
      padding-left: 0;
    }
    &:last-of-type {
      padding-right: 250px;
    }

    .image-holder {
      overflow: hidden;
      height: calc(100% - 14.28vh);
      perspective: 1000px;
      background: $assertive;
    }

    .title {
      @include font-sans($font-size-large, 32/28);
      margin: 0;
      text-decoration: none;
    }

    .date {
      @include font-sans($font-size-small, 24/16);
      margin: 1em 0 0.1em;
    }

    &.fake {
      display: none;
    }
  }
}

@media (max-width: 1200px) {
  .slider-projects-preview {
    .featured-item {
      width: calc(100vw - 296px);
    }
    .slider-scroll__item {
      &:last-of-type {
        padding-right: 100px;
      }
    }
  }
}

.__mobile {
  .slider-projects-preview {
    padding-top: 0;
    height: 50vh;
    .slider-scroll__holder-items {
      height: 50vh;
      padding-right: 0;//calc(100vw - 93px);
      padding-left: 0;
    }


    .featured-item {
      height: calc(50vh - 14.28vh);
    }

    .slider-scroll__item {
      padding: 0 0 0 $padding-sizes-tablet;
      &.fake {
        padding:0 $padding-sizes;
        background: $transparent;
        display: block;
        .image-holder {
          background: $transparent;
        }
      }
    }
  }
}

@media (max-width: $tabletPortrait) {}

.featured-item__projects-preview {
  display: none;
}

@media (max-width: $smartphone) {
  .slider-projects-preview {
    padding-top: 48px !important;
    height: auto !important;
    margin-bottom: 0;


    .slider-scroll__holder-items {
      position: relative;
      top:0;
      bottom: 0;
      left:0;
      height: auto !important;
      display: flex;
      flex-direction: column;
      padding: 0;
      will-change: transform;
    }

    .slider-scroll__item {
      padding: 0 !important;
      margin-bottom: 5em;

      .image-holder {
        @include aspect-ratio-obj(1284, 912);
      }

      &.fake {
        display: none !important;
      }
      &:last-of-type {
        display: none !important;
      }
    }

    .featured-item {
      margin-top:0;
      height: auto !important;
      pointer-events: all;
      visibility: visible;
    }
  }

  .featured-item__projects-preview {
    display: block;
    margin-top: 0 !important;
    text-align: center;
  }
}