.image-double-block {
  margin-top: 11.75em;
  margin-bottom: 11.75em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &:first-of-type {
    margin-top: 0;
  }

  .holder {
    position: relative;
    width: calc(50% - 24px);

    > .media-holder {
      min-height: 100%;
      //position: static;
    }

    img {
      width: 100%;
      height: auto !important;
    }
  }

  figcaption {
    @include font-sans($font-size-small, 32/20);
    color: $dark;
    margin-top: 0.8em;
    padding: 0 0.8em;
    text-align: center;
  }
}

@media (max-width: $menuHideSize) {
  .image-double-block {
    margin-top: 5.25em;
    margin-bottom: 5.25em;

    .holder {
      width: calc(50% - 12px);
    }
  }
}

@media (max-width:$tabletPortrait) {
  .tablet-break {
    margin-top: 5.25em;
    margin-bottom: 5.25em;
    flex-direction: column;

    .holder {
      margin: 12px 0;
      width: 100%;
    }
  }
}

@media (max-width: $smartphone) {
  .image-double-block {
    margin-top: 35px;
    margin-bottom: 35px;

    flex-direction: column;

    .holder {
      margin: 12px 0;
      width: 100%;
    }
  }
}