//sass --watch app/resources/scss/main.scss:public/resources/css/main.min.css
//sass --watch app/resources/scss/main.scss:public/resources/css/main.min.css  --style compressed




@import "variables";
@import "utils/normalize";
@import "utils/normalize_cuchillo";
@import "utils/utils";
@import "utils/jstags";
@import "utils/animation/basic";
@import "utils/mixins/breakpoints";

@import "icons";
@import "fonts";
@import "general";

@import "interface/containers";
@import "guides";

@import "interface/scrollbar";
@import "interface/links/links";
@import "interface/links/stroke_link";
@import "interface/links/underline_link";
@import "interface/links/button_link";
@import "interface/links/line-through_link";
@import "interface/links/underline_stroke_link";
@import "interface/links/underline_link";
@import "interface/links/menu_link";
@import "interface/links/basic-underline_link";
@import "interface/links/color_link";
@import "interface/links/effects";

@import "interface/progress_bar";
@import "interface/h1";
@import "interface/decos";

@import "interface/forms/forms";

@import "default";

@import "layout/header/header";
@import "layout/header/button_toggle";
@import "layout/footer/footer";
@import "layout/sidemenu";
@import "layout/filtermenu";
@import "layout/background";
@import "layout/project-detail";

@import "components/section-home";
@import "components/section-faq";
@import "components/section-news";
@import "components/section-projects";
@import "components/section-contact";
@import "components/featured-item";
@import "components/text-block";
@import "components/image-block";
@import "components/image-double-block";
@import "components/blockquote-small";
@import "components/slider-scroll-block.scss";
@import "components/slider-projects-preview.scss";
@import "components/slider-projects-related.scss";
@import "components/slider-images";

@import "interface/video/video-block";

@import "layout/cookies";
@import "layout/loader";

