
%underline-stroke-link {
  outline: 0;
  padding: 0.3em 0;
  margin: 0;
  text-decoration: none;
  position: relative;
  text-transform: uppercase;
  &:hover {  text-decoration: none;  }
}

@mixin underline-stroke-link() {
  @extend %underline-stroke-link;
  color: $white;

  svg {
    position: absolute;
    left:0;
    bottom:0;
    width: 100%;
    height: 2px;
    stroke-width: 1px;
    pointer-events: none;
  }
}