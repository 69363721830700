
.slider-projects-related {
  position: relative;
  width: 100%;
  margin-bottom: 6.6em;
  //margin-left: -$padding-sizes;
  padding: 100px 0 180px;

  &:before {
    @include pseudo-element-absolute();
    height: 100%;
    width: 150%;
    top:0;
    left:-25%;
    background: $positive;
  }


  .slider-scroll {
    padding-top: 100px;
  }

  .title {
    @include font-sans($font-size-large, 32/28);
    font-weight: 400;
    margin: 0 0 70px;
  }

  .slider-projects-related__content {
    position: relative;
    width: 100%;
    height: calc(100vh - 222px);
    //max-height: 600px;
  }

  .slider-scroll__holder-items {
    @include z-index(2);
    padding: 0;
    height: calc(100vh - 222px);
    //max-height: 600px;
    pointer-events: none;
  }

  .slider-scroll__item {
    @include no-effect-link();
    padding: 0 0 0 $padding-sizes;
    pointer-events: all;
    color: $black;

    &:first-of-type {
      padding-left: 0;
    }
    &:last-of-type {
      //padding-right: $padding-sizes;
    }

    .image-holder {
      overflow: hidden;
      height: calc(100% - 14.28vh);
      perspective: 1000px;
      background: $assertive;
    }

    .title {
      @include font-sans($font-size-large, 32/28);
      margin: 0;
      text-decoration: none;
    }

    .date {
      @include font-sans($font-size-small, 24/16);
      margin: 1em 0 0.1em;
    }

    &.fake {
      display: none;
    }
  }
}

@media (max-width: $menuHideSize) {

  .slider-projects-related {
    margin-left: -$padding-sizes-tablet;
    padding-left: $padding-sizes-tablet;
    padding-right: $padding-sizes-tablet;

    .slider-scroll__item {
      padding-left: $padding-sizes-tablet;
    }

    .slider-scroll__item {
      &:last-of-type {
        padding-right: 0;
      }
    }
  }
}

@media (max-width: $tabletPortrait) {
  .slider-projects-related {
    padding: 100px $padding-sizes-tablet 72px;

    .slider-scroll {
      padding-top: 0;
    }

    .title {
      margin-bottom: 36px;
    }

    .slider-projects-related__content {
      height: 50vh;
    }

    .slider-scroll__holder-items {
      height: 50vh;
      padding-right: 0//calc(100vw - 93px);
    }

    .featured-item {
      height: calc(50vh - 14.28vh);
    }


  }
}


@media (max-width: $smartphone) {
  .slider-projects-related {
    padding-top: 48px;
    padding-bottom: 48px;
    height: auto;
    margin-bottom: 0;
    margin-left: 0;

    .title {
      font-size: $font-size-base;
    }

    .slider-projects-related__content {
      height: auto;
      max-height: 9999px;
    }

    .slider-scroll__holder-items {
      position: relative;
      top:0;
      bottom: 0;
      left:0;
      height: auto;
      max-height: 9999px;
      display: flex;
      flex-direction: column;
      padding: 0;
      will-change: transform;
    }

    .slider-scroll__item {
      padding: 0;
      margin-bottom: 5em;

      .image-holder {
        @include aspect-ratio-obj(1284, 912);
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .featured-item {
      margin-top:0;
      height: auto;
      pointer-events: all;
      visibility: visible;
    }
  }
}