$font-sans:          'Graphik Web', Helvetica, Arial, sans-serif;
$font-sans-bold:          'Graphik Web', Helvetica, Arial, sans-serif;
$font-family-serif:               '', serif;
$font-mono: monospace, monospace;;
$font-family-icon:               'poloicons';


@mixin font-sans($size, $line-height:$line-height-base) {
  font-family: $font-sans;
  font-size: em($size);
  font-weight: 400;
  line-height: $line-height;
}

@mixin font-sans-bold($size, $line-height:$line-height-base) {
  font-family: $font-sans-bold;
  font-size: em($size);
  font-weight: 700;
  line-height: $line-height;
}

@mixin font-sans-light($size, $line-height:$line-height-base) {
  font-family: $font-sans-bold;
  font-size: em($size);
  font-weight: 300;
  line-height: $line-height;
}


@mixin font-serif($size, $line-height:$line-height-base) {
  font-family: $font-serif;
  font-size: em($size);
  line-height: $line-height;
  font-weight: 400;
}


@mixin font-mono($size, $line-height:$line-height-base) {
  font-family: $font-mono;
  font-size: em($size);
  font-weight: normal;
  line-height: $line-height;
}





// Font-Sizes
// -------------------------------

$font-size-xsmall:                11px !default;
$font-size-small:                 16px !default;
$font-size-base:                  20px !default;
$font-size-large:                 28px !default;
$font-size-title:                 48px !default;
$font-size-title-smartphone:                 28px !default;

$font-summary:                 24px !default;
$line-height-base:                32/20 !default; // 20/14
@mixin line-height($font-size) {
  line-height:                    floor($font-size*$line-height-base);
}

$title-size-smartphone:24px;
// Base
// -------------------------------