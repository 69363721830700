$a: 200px;

.main-sidemenu {
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  @include z-index($z-index-sidemenu);
  background: $white;
  pointer-events: all;
}

.main-sidemenu__navigation {
  display: flex;
  flex-direction: column;
  padding: 276px 117px 0;

  a {
    @include font-sans($font-size-title-smartphone);
    @include color-link($dark, $black);
    margin-bottom: 0.8em;
  }
}

.main-sidemenu__contact {
  position: absolute;
  width: 100%;
  left:0;
  bottom:0;
  padding: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  a {
    @include font-sans($font-size-base);
    @include menu-link($black);

    &:last-of-type {
      &:after {
        @include pseudo-element-absolute();
        width: 100%;
        height: 2px;
        background: $black;
        left: 0;
        bottom: -4px;
      }
    }
  }
}





@media (max-width: $tabletPortrait) {
  .main-sidemenu {
    padding: 0 24px;
  }
  .main-sidemenu__navigation {
    padding: 0 12.5% 0;
    height: 100%;
    justify-content: center;
  }
}
@media (max-width: $smartphone) {
  .main-sidemenu {
    padding: 0 24px;
  }

  .main-sidemenu__navigation {
    padding: 0 12.5% 0;
    a {
      font-size: $font-size-base;
    }
  }
}


