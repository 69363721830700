.section-home {
  margin-top: -92px;
}
.section-home__header {
  margin-top: 0;
  margin-bottom: 0;

  h2 {
    font-weight: 400;
    font-size: 14.28vh;
    line-height: 0.91;
    margin: 0;
    //padding-bottom: 14.28vh;
    letter-spacing: calc(-14.28vh / 20);

    span {
      display: block;
    }
  }
}

.section-home__scroll-indicator {
  position: fixed;
  top:0;
  width: 80px;

  a {
    position: absolute;
    display: block;
    left: 0;
    bottom: 40px;
    @include no-effect-link();
  }

  svg {
    width: 50px;
    height:70px;
    height: auto;
    stroke: $black;
    stroke-width: 2px;
    path {
      vector-effect: non-scaling-stroke;
    }
  }
}

@media (max-width: $menuHideSize) {
  .section-home__header {
    h2 {
      font-size: 12.28vh;
    }
  }
}

@media (max-width: $tabletPortrait) {
  .section-home__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 80vh;

    h2 {
      font-size: 12.28vh;
        position: absolute;
        top:calc(50% - 80px);
        transform: translateY(-50%);
        padding: 0;

    }
  }

  .section-home__scroll-indicator {
    a {
      left:4px;
      bottom:24px;
    }

    svg {
      width: 26px;
      height: auto;
    }
  }
}

@media (max-width: 600px) {
  .section-home__header {
    h2 {
      font-size: 7vh;
    }
  }
}

@media (max-width: $smartphone) {
  .section-home {
    margin-top: -92px;
  }
  .section-home__header {

    h2 {
      top:calc(50% - 71px);
      font-size: 48px;
      line-height: 0.91;
      letter-spacing: -2px;
    }
  }


  .section-home__scroll-indicator {
    left:50%;
    width: 26px;
    margin-left: -13px;

    a {
      left:0;
      bottom:24px;
    }

    svg {
      width: 26px;
      height: auto;
    }
  }
}

@media (max-width: 320px) {
  .section-home__header {
    h2 {

    }
  }
}
