.__loading {
  position: relative;
  &:after {
    @include z-index(99999);
    position: absolute;
    content:' ';
    display: block;
    top:0px;
    left:0px;
    width: 100%;
    height: 100%;
  }
}

._cursorFollow,
.__cursorFollow {
  @include z-index(1);
  transform-origin: 50% 50%;
  transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  &.hovered {
    @include z-index(2);
  }
}

.__disabled {
  pointer-events: none !important;
}



.__holderSplit {
  position: relative;

  > span {
    opacity: 0;
  }
  .splitLine {
    position: absolute;
    left: 0;
    top: 0;
  }
}

.__mask {
  position: absolute;
  left:0;
  top:0;
  width: 100%;
  height: 100%;
  @include z-index(99);
}