%line-through-link {
  position: relative;
  cursor: pointer;
  display: inline-block;
  outline: 0;
  text-decoration: none;

  &:before {
    position: absolute;
    display: block;
    content: '';

    top:50%;
    left:0;

    width:0;

    @include animation-name(underline-out);
    @include animation-duration(1s);
    @include animation-fill-mode(forwards);
    @include animation-timing-function(cubic-bezier(0.190, 1.000, 0.220, 1.000));
  }



  &.link-active {
    pointer-events: none;
    &:before {
      @include animation-name(underline-linkctive);
      @include animation-duration(0.0s);
      @include animation-fill-mode(forwards);
    }
  }

  &.loading {
    pointer-events: none;
    &:before {
      @include animation-name(underline-inout);
      @include animation-duration(1s);
      @include animation-fill-mode(forwards);
      @include animation-timing-function(cubic-bezier(0.190, 1.000, 0.220, 1.000));
      @include animation-iteration-count(999);
    }
  }
}

@media (pointer:fine) {
  %line-through-link {
    &:hover {
      text-decoration: none;

      &:before {
        @include animation-name(underline-in);
        @include animation-duration(.3s);
        @include animation-fill-mode(forwards);
        @include animation-timing-function(cubic-bezier(0.250, 0.460, 0.450, 0.940));
      }
    }
  }
}

@mixin line-through-link($color:$black, $barColor:$assertive, $barHeight:1px) {
  @extend %line-through-link;
  color:$color;
  &:before {
    background-color: $barColor;
    height: $barHeight;
    @include transformY(-$barHeight/2);
  }
}