.blockquote-small {
  margin-top: 11.75em;
  margin-bottom: 11.75em;
  text-align: center;

  &:first-of-type {
    margin-top: 0;
  }

  p {
    @include font-sans($font-size-large, 32/28);
    /*&:before { content: '“'; }
    &:after { content: '”'; }*/
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  footer {
    margin: 1.5em;
    text-align: center;
    @include font-sans($font-size-base, 24/16);
  }

  cite {
    font-style: normal;
  }
}

@media (max-width: $menuHideSize) {
  .blockquote-small {
    margin-top: 5.25em;
    margin-bottom: 5.25em;
  }
}
@media (max-width: $smartphone) {
  .blockquote-small {
    margin-top: 35px;
    margin-bottom: 35px;
    padding-left: 32px !important;
    padding-right: 32px !important;

    p {
      font-size: 16px;
    }
    footer {
      font-size: 16px;
    }
  }
}