.slider-scroll {
  position: relative;
  overflow: visible;
}

.slider-scroll__holder-items {
  position: absolute;
  bottom: 0;
  left:0;
  height: 100vh;
  display: flex;
  will-change: transform;
}
.slider-scroll__item {
  height: 100%;
  padding:0 $padding-sizes 0 0;

  &:last-of-type {
    padding-right: 0;
  }

  .image-holder {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;

    > div {
      position: absolute;
      top:0;
      left:0;
      width: 100%;
      height: 100%;
      background-position: 50%;
      background-size: cover;
      background-repeat: no-repeat;
    }
    > img {
      position: absolute;
      top:0;
      left:0;
      width: 100%;
      height: 100%;
    }
  }
}


@media (max-width: $menuHideSize) {
  .slider-scroll__item {
    padding:0 $padding-sizes-tablet 0 0;
  }
}

@media (max-width: $smartphone) {
  .slider-scroll__item {
    padding:0 $padding-sizes-mobile 0 0;
  }
}