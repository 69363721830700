$module-title-size:12px;
@mixin module-title($color, $barColor, $size:$module-title-size) {
  position: absolute;
  top:40px;
  left:40px;
  margin: 0;
  font: normal normal bold #{em($size)}/#{1} $font-family-sans-serif;
  color:$color;
  text-transform: uppercase;

  &:before {
    display: block;
    content: ' ';
    width: 15px;
    margin-bottom: 20px;
    border-bottom: 2px solid $barColor;
  }
}

@mixin addTopLine($bg) {
  &:before {
    display: block;
    content: '';
    border-bottom: 8px solid $bg;
    width: 184px;
    margin: 0 0 0.5em;
  }
}

@mixin SectionLines($bg) {
  &:before {
    position: absolute;
    content: '';
    top:0;
    left:0;
    border-bottom: 8px solid $bg;
    width: 100%;
  }

  &:after {
    position: absolute;
    content: '';
    bottom:0;
    left:0;
    border-bottom: 8px solid $bg;
    width: 100%;
  }
}

