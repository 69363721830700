.text-block {
  margin-top: 11.75em;
  margin-bottom: 11.75em;

  &:first-of-type {
    margin-top: 0;
  }

  h1, h2, h3 {
    font-weight: 400;

  }

  ul, ol {
    @include font-sans($font-size-base, 32/20);
  }

  p {
    @include font-sans($font-size-base, 32/20);
    &:first-of-type { margin-top: 0; }
    &:last-of-type { margin-bottom: 0; }
    b, strong {
      font-weight: 400;
      text-decoration: underline;
    }
  }

  a {
    @include color-link($dark, $black);
    &:hover {
      text-decoration: underline;
    }
  }

  &.font-big {
    text-align: center;
    p,ul, ol {
      @include font-sans(60px, 80/60);
      letter-spacing: -2.4px;
    }
  }
}

@media (max-width: $menuHideSize) {
  .text-block {
    margin-top: 5.25em;
    margin-bottom: 5.25em;

    &.font-big {
      p {
        @include font-sans(48px, 64/48);
        letter-spacing: -1.2px;
      }
    }
  }
}

@media (max-width: $smartphone) {
  .text-block {
    margin-top: 35px;
    margin-bottom: 35px;

    &.font-big {
      p {
        @include font-sans(28px, 32/28);
        letter-spacing: 0;
      }
    }
  }
}