.loader {
  position: fixed;
  @include z-index($z-index-loader);
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: $white;
}

.loader-bg {
  position: absolute;
  top:0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background-color: $white;
}

.loader__logo-holder {
  position: absolute;
  @include z-index(2);
  top:50%;
  left:50%;
  width: 60%;
  height: 60%;
  transform: translate3D(-50%, -50%, 2px);
  opacity: 0;

  svg {
    width: 100%;
    height: 100%;
    fill:$black;
    opacity: 1;
  }
}

.loader__logo-bar{
  width: 100%;
  height: 2px;
  top:-20px;
  left:0;
  @include z-index(3);
  background: $black;
}
