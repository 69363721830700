.image-block {
  margin-top: 11.75em;
  margin-bottom: 11.75em;

  &:first-of-type {
    margin-top: 0;
  }

  img {
    width: 100%;
    height: auto !important;
  }

  figcaption {
    @include font-sans($font-size-small, 32/20);
    color: $dark;
    margin-top: 0.8em;
    padding: 0 0.8em;
    text-align: center;
  }
}

@media (max-width: $menuHideSize) {
  .image-block {
    margin-top: 5.25em;
    margin-bottom: 5.25em;
  }
}
@media (max-width: $smartphone) {
  .image-block {
    margin-top: 35px;
    margin-bottom: 35px;
  }
}