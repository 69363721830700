$size: 60px;

.featured-item {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  @include font-sans($size, 1);
  color: $black;
  letter-spacing: -2.4px;

  svg {
    width: 64px;
    height: auto;
    stroke: $black;
    stroke-width: 2px;
    path {
      vector-effect: non-scaling-stroke;
    }
    margin-left: 0.6em;
    @include transform-3D(0,0.1em,0);
    transition: transform .8s cubic-bezier(0.165, 0.840, 0.440, 1.000);
  }

  .featured-item__link {
    @include no-effect-link();
    color: $black;
    &:hover {
      svg {
        @include transform-3D(20px,0.1em,0);
        transition: transform .4s cubic-bezier(0.165, 0.840, 0.440, 1.000);
      }
    }
  }
}

@media (max-width: $menuHideSize) {
  .featured-item {
    font-size: $font-size-title-smartphone;
    letter-spacing: -0.2px;
    svg {
      width: 32px;
    }
  }
}

@media (max-width: $smartphone) {
  .featured-item {
    background: $assertive;
    color: $dark;
    font-size: $font-size-base;
    letter-spacing: 0;
    padding: 1.5em 1em;
    height: 80px;
    margin-top: 124px;
    width: 100vw !important;
    margin-left: -24px;

    .featured-item__link {
      color: $dark;
      &:hover {
        color: $dark;
      }
    }

    svg {
      display: none;
    }
  }
}