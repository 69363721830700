.project-detail {}
@media (max-width: $menuHideSize) {
  /*.project-detail {
    padding-top: 80px;
  }
  .project-detail__header-image {
    height: calc(100vh - 80px);
  }*/
}
@media (max-width: $smartphone) {
  /*.project-detail {
    padding-top: 0;
  }
  .project-detail__header-image {
    height: auto;
  }*/
}


.project-detail {
  header {
    margin: 0;
  }
}

.project-detail__header-image {
  position: relative;
  overflow: hidden;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;


  .scroll-indicator {
    position: absolute;
    height: 100%;
    width: 80px;
    top:0;
    left:50%;
    margin-left: -40px;

    a {
      position: absolute;
      display: block;
      left: 0;
      bottom: 40px;
      @include no-effect-link();
    }

    svg {
      width: 50px;
      height:70px;
      stroke: $white;
      stroke-width: 2px;
      path {
        vector-effect: non-scaling-stroke;
      }
    }
  }
}

@media (max-width: $tabletPortrait) {
  .project-detail__header-image {
    .scroll-indicator {
      width: 26px;
      margin-left: -13px;

      a {
        bottom: 24px;
      }

      svg {
        width: 26px;
        height: auto;
      }
    }
  }
}



.project-detail__header-info {
  margin-top: 11.75em;
  margin-bottom: 11.75em;

  h1 {
    @include font-sans($font-size-title, 64/48);
    letter-spacing: -($font-size-title/40);
  }

  .holder {
    //display: flex;
    //justify-content: flex-start;
    margin: .5em 0 0;
  }

  .info-small {
    position: absolute;
    flex-grow: 0;
    top:0;
    left:0;
    width: calc((100% - 840px) / 2);
    padding-right: 10px;
    color: $dark;
    @include font-sans($font-size-small, 24/16);

    h1, h2, h3 {
      font-weight: 400;
    }

    p {
      margin: 0;
    }

    a {
      @include color-link($dark, $black);
    }
  }

  .info-main {

    @include font-sans($font-size-base, 32/20);
    h1, h2, h3 {
      font-weight: 400;
    }

    p {
      &:last-of-type, &:first-of-type {
        margin: 0;
      }
      b, strong {
        font-weight: 400;
        text-decoration: underline;
      }
    }
    a {
      @include color-link($dark, $black);
    }
  }
}
.project-detail__header-info__alternative {
  margin-top: 11.75em;
  margin-bottom: 11.75em;
  .info-small {
    width: 100%;
    max-width: 360px;
    margin: 0 auto;
    display: none;
    color: $dark;
    @include font-sans($font-size-small, 24/16);

    p {
      margin: 0;
    }

    a {
      @include color-link($dark, $black);
    }
  }
}
@media (max-width: 1500px) {
  .project-detail__header-info {
    .info-small {
      padding-top: 0.4em;
      font-size: 16px;
    }
  }
}

@media (max-width: 1400px) {
  .project-detail__header-info {
    .holder {
      justify-content: center;
    }
    .info-small {
      display: none;
    }
  }
  .project-detail__header-info__alternative {
    .info-small {
      max-width: 100%;
      display: block;
    }
  }
}




.project-detail__footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 6.6em 0;

  .social {
    flex:1 0 auto;
    @include font-sans($font-size-base, 32/20);
    display: flex;
    flex-direction: row;

    a {
      @include menu-link($dark, $dark);
      margin-left: 1em;
      opacity: 0;
      height: 32px;
      transition: opacity 0.4s ease;
    }

    &:hover {
        a {
          opacity: 1;
          transition: opacity 0.4s ease;
        }
      }
  }

  .button-up {
    @include no-effect-link();
    width: 50px;
    height:70px;
    height: auto;
    transform-origin: 50%;

      svg {
        width: 50px;
        height:70px;
        height: auto;
        stroke: $black;
        stroke-width: 2px;
        path {
          vector-effect: non-scaling-stroke;
        }
      }
  }
}


@media (max-width: $menuHideSize) {
  .project-detail {
    .container-mid, .container-padding {
    /*  padding-left: $correccion-padding-tablet;
      padding-right: $correccion-padding-tablet;*/
    }
  }

  .project-detail__header-info {
    margin-top: 5.25em;
    margin-bottom: 5.25em;
  }

  .project-detail__header-image {
    position: relative;
    overflow: hidden;
    height: calc(50vh + 80px);
  }

  .project-detail__footer {
    margin: 3.3em 0;

    .social {

      a {
        opacity: 1;
      }
    }
  }
}

@media (max-width: $smartphone) {
  .project-detail {
    .container-mid, .container-padding {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .project-detail__header-info {
    margin-top: 35px;
    margin-bottom: 35px;

    h1 {
      font-size: $font-size-title-smartphone;
    }
  }

  .project-detail__header-info__alternative {
    margin-top: 35px;
    margin-bottom: 35px;
  }

  .project-detail__header-image {
    img {
      width: 150%;
      left:auto;
      right: 0;
      transform: translateX(0) translateY(-50%);
    }
  }


  .project-detail__footer {
    align-items: flex-start;
    margin: 45px 0;

    .social {
      flex-direction: column;

      a {
        opacity: 1;
        margin-left: 0;
      }
    }

    .button-up {
      padding-top: 12px;
      width: 36px;
      height: auto;

      svg {
        width: 36px;
        height: auto;
      }
    }
  }
}