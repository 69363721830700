.section-projects {
  position: relative;
  margin-top: -20px;
  min-height: 100vh;

  .holder {
    width: calc(100% + 48px);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -24px;
  }
  .chivato {
    border-bottom: 1px solid #000;
  }
}

@media (max-width: $menuHideSize) {

}

@media (max-width: $tabletPortrait) {

}

@media (max-width: $smartphone) {

}

.section-projects__article {
  width: 50%;
  margin-bottom: 7.75em;
  color: $black;

  padding: 0 24px;

  /*&:nth-of-type(odd) {
    padding-right: 24px;
  }
  &:nth-of-type(even) {
    padding-left: 24px;
  }*/

  .title {
    @include font-sans($font-size-large, 32/28);
    margin: 0;
    text-decoration: none;
  }

  .date {
    @include font-sans($font-size-small, 24/16);
    margin: 1em 0 0.1em;
  }

  a {
    @include no-effect-link();
    color: $black;
  }
}

@media (max-height: 850px) {
  .section-projects__article {
    margin-bottom: 4em;
  }
}


@media (max-width: $menuHideSize) {
  .section-projects {
    .holder {
      width: calc(100% + 24px);
      margin: 0 -12px;
    }
  }

  .section-projects__article {

    /*&:nth-of-type(odd) {
      padding-right: 12px;
    }
    &:nth-of-type(even) {
      padding-left: 12px;
    }*/

    padding: 0 12px;
  }

}

@media (max-width: $smartphone) {
  .section-projects {
    .holder {
      width: 100%;
      margin: 0;
    }
  }

  .section-projects__article {
    width: 100%;
    padding: 0 !important;
    margin-bottom: 5em;

    .title {
      font-size: $font-size-title-smartphone;
    }
  }
}