.section-news {
  position: static;
  margin-top: -20px;

  .holder {
    width: 100%;
    max-width: $container-m;
  }
}

@media (max-width: $smartphone) {
  .section-news {
    padding-left: 0;
  }
}

.section-news__article {
  margin-bottom: 4.65em;
  cursor: pointer;

  .title,
  .date {
    @include font-sans($font-size-title, 64/48);
    margin: 0;
  }

  h1, h2, h3 {
    font-weight: 400;
  }

  ul, ol {
    @include font-sans($font-size-base, 32/20);
  }

  p {
    @include font-sans($font-size-base, 32/20);
    margin-top: 0.5em;
    b, strong {
      font-weight: 400;
      text-decoration: underline;
    }
  }

  a {
    @include color-link($dark, $black);
    &:hover {
      text-decoration: underline;
    }
  }
}

.section-news__article__image {
  position: fixed;
  @include z-index($z-index-cookies);
  top:0;
  left:0;
  width: 30vw;
  display: none;
  pointer-events: none;

  .media-holder {
    @include transform-3D(20px,20px,0);
  }
}
.__hover {
  .section-news__article__image {
    display: block;
  }
}

@media (max-width: $tabletPortrait) {
  .section-news__article__image {
    position: relative;
    top:auto;
    left:auto;
    width: 100%;
    margin-top: 2.8em;
    display: block;

    .media-holder {
      @include transform-3D(0,0,0);
    }
  }
}


@media (max-width: $smartphone) {
  .section-news {
    .holder {
      padding: 0;
    }
  }
  .section-news__article {
    .title, .date {
      font-size: $font-size-title-smartphone;
    }
  }
}