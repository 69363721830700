.main-header {
  top:0;
  left: 0;
  width: 100%;

  /**/

  position: fixed;
  @include z-index($z-index-header);
  top:0;
  left:0;

}

.main-header__bg {
  @include z-index($z-index-header-bg);
  position: fixed;
  width: 100%;
  top:0;
  left:0;
  width: 100%;
  height: 96px;
  background: $white;
}

.main-header__title {
  position: absolute;
  @include z-index($z-index-header + 1);
  top: 35px;
  color: $black;
  display: inline-block;
  @include no-effect-link();

  .title {
    font-size: 25px;
    width: 250px;
    margin: 0;
  }
}

.main-header__links {
  position: relative;
  @include z-index($z-index-header);
  top:0;
  left:0;
  width: 100%;
  height: 96px;

  padding: 0 25% 0 25%;

  .navigation {
    height: 100%;
    flex: 1 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 5px;

    a {
      @include font-sans($font-size-base, 24/20);
      @include menu-link($black);
    }
  }

  .contact {
    position: absolute;
    top:0;
    right: 0;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 5px;

    a {
      position: relative;
      @include font-sans($font-size-base, 24/20);
      @include menu-link($black);
      margin-left: 4.8em;

      &:last-of-type {
        &:after {
          @include pseudo-element-absolute();
          width: 100%;
          height: 2px;
          background: $black;
          left: 0;
          bottom: -4px;
        }
      }
    }
  }
}

@media (max-width: 1600px) {
  .main-header__links {
    .navigation {
      justify-content: flex-start;
      a {
        margin-right: 1.5em;
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
    .contact {
      width: 300px;
      a {
        margin-left: 1.5em;
        &:first-of-type {
          margin-left: 0;
        }
      }
    }
  }
}


@media (max-width: $contactHideSize) {
  .main-header__links {
    padding-right: 0;
    .navigation {
      display: flex;
      justify-content: flex-end;
      margin: 0 0 0 auto;
      a {
        margin-right: 0;
        margin-left: 2.4em;
      }
    }
    .contact {
      display: none;
    }
  }
}

@media (max-width: $menuHideSize) {
  .main-header {
    position: fixed;
    @include z-index($z-index-header);
    top:0;
    left:0;
    background: $white;
    height: 80px;
  }

  .main-header__bg{
    height: 80px;
  }

  .main-header__title {
    left: $padding-sizes-mobile;
    top: 27px;
  }

  .main-header__title-link{
    position: absolute;
    top:23px;
    left:24px;
    display: block;
    width: 75%;
  }

  .main-header__links {
    display: none;
  }
}



@media (max-width: $smartphone) {
  .main-header {
    height: 71px;
  }

  .main-header__bg{
    height: 71px;
  }

  .main-header__title {
    top: 21px;
    left: $padding-sizes-mobile;
  }
}