.section-faq {
  margin-top: -20px;

  .holder {
    position: relative;
    width: 100%;
  }
}


@media (max-width: $smartphone) {
  .section-faq {
    padding-left: 0;
  }
}

.faq__acordion {
  margin-bottom: 4em;

  a {
    @include color-link($black, $dark);
    position: relative;
    color: $black;
  }

  .arrow {
    position: absolute;
    display: block;
    width: 70px;
    height: 50px;
    top:0.2em;
    left:-100px;
    transform-origin: 50%;
    transform: rotate(0deg);
    transition: all 300ms cubic-bezier(0.445, 0.050, 0.550, 0.950);
    stroke: $black;
    stroke-width: 2px;
    path {
      vector-effect: non-scaling-stroke;
    }
  }

  .__open {
    .arrow {
      transform: rotate(90deg);
    }
  }

  .title {
    @include font-sans($font-size-title, 64/48);
    margin: 0;
  }
  h1, h2, h3 {
    font-weight: 400;
  }
  p {
    @include font-sans($font-size-base, 32/20);
    b, strong {
      font-weight: 400;
      text-decoration: underline;
    }
    a {
      @include color-link($dark, $black);
      &:hover {
        text-decoration: underline;
      }
    }
  }
}


@media (max-width: $menuHideSize) {


  .faq__acordion {
    .arrow {
      margin: 0;
      padding: 0;
      width: 36px;
      height: 26px;
      top:0.35em;
      left:-50px;

      svg {
        position: absolute;
        top:0;
        left:0;
      }
    }
  }
}

@media (max-width: $smartphone) {
  .section-faq {
    .holder {
      padding: 0;
    }
  }
  .faq__acordion {
    .title {
      font-size: $font-size-title-smartphone;
    }
    .arrow {
      position: relative;
      display: inline-block;
      height: 1px;
      left:auto;
      top:auto;
      transform: translateX(0.2em) translateY(-0.3em) rotate(0deg);

      svg {
        position: absolute;
        top:50%;
        left:50%;
        @include transform-3D(-50%, -50%, 1px);
      }
    }

    .__open {
      .arrow {
        transform: translateX(0.2em) translateY(-0.3em) rotate(90deg);
      }
    }
  }
}