$s:24px;

.default-form {

  display: flex;
  flex-wrap: wrap;

  .input-holder {
    position: relative;
    width: 100%;
    background: transparent;
    border-bottom: 1px solid $black;
    margin: 0 0 1em;

    &.error {
      border-bottom: 1px solid red;
      input,textarea {
        color: red;
        &::placeholder {
          color: red;
        }
      }
    }

    label {
      position: absolute;
      top: 0.2em;
      left:0;
      transform-origin: 0 100%;
      transition: all .2s ease;
    }
    &.notvoid,
    &.focus {
      label {
        top: -1em;
        transform: scale(0.7, 0.7);
      }
    }
  }



  input,textarea {
    background: $transparent;
    outline: 0;
    border: 0 solid transparent;
    border-radius: 0;
    width: 100%;
    height: auto;
    padding: 0.2em 0;
    transition: all .4s cubic-bezier(0.165, 0.840, 0.440, 1.000);

    &::placeholder {
      opacity: 1;
      color: $black;
    }
  }

  button {
    @include button-link();
    width: 100%;
    &:after,&:before {
      display: none;
    }
  }

  &.button-loading {
    color: $transparent;
    &:before {
      content: 'ENVIANDO...';
      text-align: center;
      position: absolute;
      top:0;
      left:0;
      width: 100%;
      height: 100%;
      padding-top: 0.8em
    }
  }
}


@media (max-width: $tabletPortrait) {
  .default-form {
    .col {
      flex: 1 0 100%;
      width: 100%;
    }
  }
}

