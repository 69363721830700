.main-footer {
  position: relative;
  @include z-index($z-index-footer);
  top:0;
  left: 0;
  width: 100%;
  background: $white;
  @include font-sans($font-size-base, 32/20);
  margin-top: 2.5em;
  padding-bottom: 2em;
}

.main-footer__title-aux,
.main-footer__title {
  position: absolute;
  top: 0px;
  font-size: 25px;

  color: $black;
  @include no-effect-link();
  @include z-index($z-index-header);
}
.main-footer__title-aux {
  display: none;
}

.main-footer__content,
.main-footer__content-legal {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;

  .col {
    width: 33.333%;
    padding-right: 10px;
  }
}

.main-footer__content__contact {
  a {
    @include color-link($black, $black);
    display: block;
    &:hover {
      text-decoration: underline;
    }
  }
}

.main-footer__content__social {
  display: flex;
  justify-content: flex-end;
  padding-right: 0 !important;

  a {
    @include color-link($black, $black);
    margin-left: 1.5em;
    &:hover {
      text-decoration: underline;
    }
  }
}

.main-footer__content-legal {
  margin-top: 4em;
  color: $dark;
}
.main-footer__content-legal__links {
  a {
    display: block;
    @include color-link($dark, $dark);
    &:hover {
      text-decoration: underline;
    }
  }
}

@media (max-width: $largeScreen) {
  .main-footer__content-legal {
    font-size: 16px;
  }
}

@media (max-width: $contactHideSize) {
  .main-footer {
    .container-full-layout {
      padding-left: $padding-layout;
    }
  }

  .main-footer__content__social {
    a {
      .large {
        display: none;
      }
    }
  }
  .main-footer__content {
    .col {
      width: 42%;
    }
    .main-footer__content__social {
      width: 16%;
      justify-content: flex-end;

      a {
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
  .main-footer__content-legal {
    .col {
      width: 42%;
    }
  }
}

@media (max-width: $contactHideSize) {

}

@media (max-width: $menuHideSize) {
  .main-footer {
    font-size: 18px;

    .container-full-layout {
      padding-left: 0;
    }
  }
  .main-footer__title{
    display: none;
  }
}

@media (max-width: $smartphone) {
  .main-footer {
    font-size: 16px;

    .holder {
      padding-left: $padding-sizes-mobile;
      display: flex;
      flex-direction: column;
    }


  }

  .main-footer__content {
    order:1;
  }

  .main-footer__title-aux {
    position: relative;
    display: block;
    top:auto;
    left:auto;
    order:2;
    margin: 58px 0;
  }

  .main-footer__content-legal {
    order:3;

  }

  .main-footer__content,
  .main-footer__content-legal {
    flex-direction: column;

    .col {
      min-width: 100%;
      width: 100%;
      margin-top: 1.45em;
      justify-content: flex-start;
      &:first-of-type {
        margin-top: 0;
      }
    }
  }



  .main-footer__content__social {
    a {
      margin: 0;
      .large {
        display: inline;
      }
      &:after {
        content: '/';
        margin: 0 0.3em;
      }
      &:last-of-type {
        &:after {
          content: '';
        }
      }
    }
  }

  .main-footer__content-legal {
    margin: 0;
  }
}
