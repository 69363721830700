
// Paths
// -------------------------------

$font-path:    "../../assets/fonts" !default;
$images-path:    "../../assets/images/" !default;

// Z-Indexes
// -------------------------------

$z-index-bg:                      1;
$z-index-wrap:                    10;
$z-index-projects:                20;
$z-index-project-detail:          30;
$z-index-breadcrumb:              35;
$z-index-header-bg:                45;
$z-index-filtermenu:                49;
$z-index-sidemenu:                50;
$z-index-footer:                  40;
$z-index-header:                  60;
$z-index-cookies:                 100;
$z-index-progress:                200;
$z-index-loader:                  300;
$z-index-cursor:                  150;
$z-index-follower:                45;
$z-index-wrap-out:                9;
$z-index-wrap-back:               19;


// Colors
// -------------------------------

$white-alpha:                     rgba(255, 255, 255, 0.2) !default;
$black-alpha:                     rgba(0, 0, 0, 0.2) !default;
$transparent:                     rgba(0, 0, 0, 0) !default;

$white: #fff;
$black: #000;
$dark: #B9B2AF;
$assertive: #F1EEED;
$positive: #F1EEED;

$facebook: #3b5998;
$twitter: #55acee;
$youtube: #ff0000;
$linkedin: #007bb5;
$whatsapp: #4dc247;
$pinterest: #cb2027;

$light: #fafbfd;
$disabled:                        #999 !default;



$colors: (
        -white: $white,
        -black: $black,
        -transparent: $transparent,
        -light: $light,
        -assertive:$assertive,
        -positive:$positive,
        -disabled:$disabled,
        -dark: $dark,
        -facebook: $facebook,
        -twitter: $twitter,
        -youtube: $youtube,
        -linkedin: $linkedin,
        -whatsapp: $whatsapp,
        -pinterest: $pinterest,
);



$margin:  0;
$padding: 100px;
$margin: 40px;
$margin-mobile: 20px;
$padding-mobile: 20px;
$header-height: 96px;
$header-height-mobile: 0px;
