.section-contact {
  display: flex;
  flex-direction: row;

  .section-contact__info {
    width: 65%;
  }

  .section-contact__image {
    width: 35%;
  }
}


.section-contact__info {
  display: flex;
  flex-direction: column;
}

.section-contact__info_article {
  position: relative;
  @include font-sans($font-size-base, 32/20);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-left: 200px;
  flex-wrap: wrap;
  margin-bottom: 190px;

  .title {
    position: absolute;
    left:0;
    top:0;
    font-size: $font-size-large;
    line-height: 1.14;
    font-weight: 400;
    margin: 0;
  }

  a {
    position: relative;
    @include color-link($black, $dark);
    &:after {
      @include pseudo-element-absolute();
      width: 100%;
      height: 2px;
      background: $black;
      left: 0;
      bottom: -4px;
      transition: background .4s cubic-bezier(0.165, 0.840, 0.440, 1.000);
    }

    &:hover {
      &:after {
        transition: background .0s;
        background: $dark;
      }
    }

    &.telephone {
      &:after {
        display: none;
      }
    }
  }

  .col, .section-contact__team-item {
    width: 50%;
    padding: 0 5%;
  }

  &.article-image {
    display: none;
  }
}

.section-contact__team-item {
  margin-bottom: 80px;
  .name {
    font-size: $font-size-base;
    line-height: 1.6;
    font-weight: 400;
    margin: 0;
  }
}


@media (max-width: $contactHideSize) {
  .section-contact {
    display: flex;
    flex-direction: column;

    .section-contact__info {
      width: 100%;
    }

    .section-contact__image {
      display: none;
    }
  }

  .section-contact__info_article {
    margin-bottom: 90px;

    .col, .section-contact__team-item {
      padding: 0;
    }

    &.article-image {
      display: block;
    }
  }
}

@media (max-width: $menuHideSize) {
  .section-contact__info_article {
    width: 75%;
    padding-left: 0;
    margin: 0 auto 50px;

    .title {
      position: relative;
      width: 100%;
      margin-bottom: 40px;
    }

    &.article-image {
      margin-bottom: 80px;
    }
  }
}

@media (max-width: $smartphone) {
  .section-contact__info_article {
    width: 100%;
    padding: 0;
    margin-bottom: 50px;

    .title {
      position: relative;
      width: 100%;
      margin-bottom: 40px;
    }

    .col, .section-contact__team-item {
      width: 100%;
      margin-bottom: 40px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &.article-image {
      margin-bottom: 50px;
    }
  }
}